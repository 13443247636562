<template>
  <VueApexCharts
    ref="chart"
    type="bar"
    width="100%"
    height="190"
    :options="chartOptions"
    :series="series"
  >
  </VueApexCharts>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import i18n from "@/lang";

export default {
  components: {
    VueApexCharts,
  },
  props: {
    chartDate: {
      type: Array,
    },
    refData: {
      type: Array,
    },
    preData: {
      type: Array,
    },
  },
  data() {
    return {
      series: [
        {
          name: i18n.t("site-statistics-searching-period"),
          data: this.refData,
        },
        {
          name: i18n.t("site-statistics-legend-compare"),
          data: this.preData,
        },
      ],
      chartOptions: {
        chart: {
          type: "bar",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "40%",
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          type: "category",
          categories: this.chartDate,
          labels: {
            style: {
              fontSize: "10px",
              colors: "#808080",
            },
          },
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: (val) => {
              return val;
            },
          },
        },
        crosshairs: {
          show: false,
        },
        legend: {
          position: "bottom",
          fontSize: "10px",
          markers: {
            fillColors: [
              "#0790FF",
              "#E34307",
              "#FF9D00",
              "#50B93C",
              "#47C1BF",
              "#CF0893",
              "#0753FF",
              "#FFBA00",
              "#6EBFFF",
              "#FF53AF",
              "#89E660",
              "#8D19FF",
              "#FF6200",
              "#01ED77",
              "#D69236",
              "#cc1414",
              "#21b5aa",
            ],
          },
          labels: {
            colors: "#808080",
          },
          itemMargin: {
            horizontal: 16,
            vertical: 4,
          },
        },

        fill: {
          colors: [
            "#0790FF",
            "#E34307",
            "#FF9D00",
            "#50B93C",
            "#47C1BF",
            "#CF0893",
            "#0753FF",
            "#FFBA00",
            "#6EBFFF",
            "#FF53AF",
            "#89E660",
            "#8D19FF",
            "#FF6200",
            "#01ED77",
            "#D69236",
            "#cc1414",
            "#21b5aa",
          ],
        },
        yaxis: {
          labels: {
            style: {
              fontSize: "10px",
              colors: "#808080",
            },
            formatter: (val) => {
              return val;
            },
          },
        },
      },
    };
  },
  mounted() {
    this.updateData();
  },
  watch: {
    chartDate: function () {
      this.updateData();
    },
  },
  methods: {
    updateData() {
      this.$refs.chart.updateOptions({
        series: [
          {
            name: i18n.t("site-statistics-column-outbreaks"),
            data: this.refData,
          },
          {
            name: i18n.t("site-statistics-legend-compare"),
            data: this.preData,
          },
        ],
        xaxis: {
          type: "category",
          categories: this.chartDate,
        },
      });
    },
  },
};
</script>
