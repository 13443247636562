<template>
  <div>
    <div class="worksite">
      <DateSelector
          @search="getList"
          :title="$t('top-select-site-statistics-site')"
      />
      <div class="box one">
        <BarChart
            :chartDate="chartDate"
            :refData="refData"
            :preData="preData"
        />
      </div>
      <Compare
          :refTotalData="refTotalData"
          :preTotalData="preTotalData"
          :refLiveData="refLiveData"
          :preLiveData="preLiveData"
          :ration="ration"
          :resultValue="'worksite1'"
      />
    </div>
  </div>
</template>

<script>
import BarChart from "@/components/charts/BarChart";
import Compare from "@/components/statistics/Compare";
import DateSelector from "@/components/statistics/DateSelector";
import moment from "moment";
import { fetchStatisticsEventList } from "@/api/statistics";
export default {
  components: { BarChart, DateSelector, Compare },
  name: "Worksite1",
  data() {
    return {
      moment: moment,
      chartDate: [],
      refData: [],
      preData: [],
      refTotalData: 0,
      preTotalData: 0,
      refLiveData: [],
      preLiveData: [],
      ration: 0,
    };
  },
  created() {
    this.$store.dispatch("store/SET_NAVBAR", { nav: 3, sub: 0 });
  },
  // mounted() {
  //   this.getEventList();
  // },
  methods: {
    // handleSelect(val) {
    //   if (val == "") {
    //     this.showDatepicker = true;
    //   } else {
    //     this.dateRangeValue = val;
    //     this.getEventList();
    //   }
    // },
    // handleCancel() {
    //   this.showDatepicker = false;
    // },
    getList(data) {
      let params = {
        dateRangeValue: data.dateRangeValue,
      };
      if (params.dateRangeValue == null) {
        params["dateRange.from"] = data.startYmd ? moment(data.startYmd).format("YYYY-MM-DD") : null;
        params["dateRange.to"] = data.endYmd ? moment(data.endYmd).format("YYYY-MM-DD") : null;
      }

      this.chartDate = [];
      this.refData = [];
      this.preData = [];

      fetchStatisticsEventList(params).then((res) => {
        if (res.data.result == 0) {
          this.refLiveData = res.data.data.reference;
          this.preLiveData = res.data.data.previous;
          this.refTotalData = res.data.data.referenceTotal;
          this.preTotalData = res.data.data.previousTotal;
          this.refLiveData.forEach((item) => {
            this.chartDate.push(this.customFormatter(item.date));
            this.refData.push(item.totalData);
          });
          this.preLiveData.forEach((item) => {
            this.preData.push(item.totalData);
          });
          this.ration = this.preTotalData ===  0
              ? ( this.refTotalData === 0 ? 0 : 100)
              : Math.floor(((this.refTotalData-this.preTotalData) / this.preTotalData) * 100);
        } else {
          alert(res.data.message);
        }
      });
    },
    customFormatter(date) {
      return moment(date).format("MM-DD");
    },
  },
};
</script>
